import React from 'react'
import headerBanner from '../../../assets/images/Header Banner.png'
import rec1 from '../../../assets/Rectangle 339.svg'
import rec2 from '../../../assets/Rectangle 338.svg'

import { Container } from 'react-bootstrap'
import ScrollDown from '../ScrollDown/ScrollDown'
import Radium, { StyleRoot } from 'radium';
import MyContainer from '../../../components/MyContainer';

const PageContainerMain = () => {
    const style = {
        fontWeight: 400,
        fontSize: '3.472vw',
        lineHeight: '4.558vw',
        font: 'Dubai',
        // Adding media query..
        '@media (max-width: 428px)': {
            'font-size': '9.346vw',
            lineHeight: '12.262vw',

        },
        '@media (max-width: 834px)': {
            'font-size': '7.194vw',
            lineHeight: '9.438vw',

        },


    }

    const style1 = {
        fontWeight: 400,
        fontSize: '1.157vw',
        lineHeight: '1.701vw',
        font: 'DIN Next LT Arabic',
        // Adding media query..
        '@media (max-width: 428px)': {
            'font-size': '3.738vw',
            lineHeight: '5.495vw',

        },
        '@media (max-width: 834px)': {
            'font-size': '2.398vw',
            lineHeight: '3.525vw',

        },
        '@media (max-width: 568px)': {
            marginTop: 20
        },
    }


    const style2 = {
        minHeight: '85vh',
        display: 'flex',
        paddingTop: 60,
        // paddingTop: '12.159814707585vw', paddingBottom: '4.159814707585vw',
        '@media (max-width: 834px)': {
            paddingTop: 140,
            minHeight: '0vh',

            display: 'block',

        },

    }

    const Imagestyle = {
        width: '39.085118702953vw',
        height: '26.577880718008vw',
        '@media (max-width: 428px)': {
            width: '84.526vw',
            height: '57.477vw',
        },
        '@media (max-width: 834px)': {
            width: '74.589vw',
            height: '50.719vw',
        },

    }
    const style3 = {
        display: 'none',
        width: '75%',
        '@media (max-width: 834px)': {
            display: 'flex',

        },
        '@media (max-width: 428px)': {
            display: 'block',
            width: '100%',

        },

    }

    const style4 = {
        width: '58.5%',
        display: 'flex',
        '@media (max-width: 834px)': {
            display: 'none',

        },


    }

    const style5 = {
        paddingTop: 0,
        '@media (max-width: 834px)': {
            paddingTop: 50,

        },
        
    }


    const Image1style = {
        right: 0,
        bottom: 0,
        zIndex:-1,

        width: '64.100vw',
        height: '55.182vw',
        // '@media (max-width: 428px)': {
        //     width: '84.526vw',
        //     height: '57.477vw',
        // },
        // '@media (max-width: 834px)': {
        //     width: '74.589vw',
        //     height: '50.719vw',
        // },

    }


    const Image2style = {
        bottom: 0,
        zIndex:-1,

        width: '100.058vw',
        height: '35.727vw',
        // '@media (max-width: 428px)': {
        //     width: '84.526vw',
        //     height: '57.477vw',
        // },
        // '@media (max-width: 834px)': {
        //     width: '74.589vw',
        //     height: '50.719vw',
        // },

    }

    return (
        <StyleRoot>
            <div className='position-relative overflow-hidden  ' id='home'>
                <img className='position-absolute' style={Image1style} src={rec1} alt='HeaderBanner' />
                <img className='position-absolute' style={Image2style} src={rec2} alt='HeaderBanner' />
                <MyContainer>
                    <div className=' justify-content-between  align-items-center' style={style2}>
                        <div className=' w-100' style={{ width: '37.869137232195vw', height: 'auto' }}>
                            <h1 className=' ' style={style}> <span >Welcome To </span><br />
                                <span style={{ fontWeight: 700 }}> <span className='text-primary'>Marmaz Tech </span> <span >Website</span></span>

                            </h1>
                            <p className='text-dark' style={style1} > We Have Another Flavor For Solutions.<br />
                                With our innovative software solutions, your vision will no longer be just a dream, Step into the future with us.</p>
                            <div className=' mt-5 ' style={style4}>
                                <button
                                    onClick={() => {
                                        console.log('jjjjjjjjjjjjj')
                                        let targetOffset = document.getElementById('contact').offsetTop - 98;
                                        window.scrollTo({ top: targetOffset, behavior: 'smooth' });
                                    }
                                    }
                                    className='border-0 d-flex justify-content-center pt-2 pb-2 rounded rounded-5 text-decoration-none  text-white btn1 w-50' style={style1} >Get Started</button>
                                <a className='border-0 ms-3 d-flex justify-content-center pt-2 pb-2 rounded rounded-5 text-decoration-none  text-primary btn2 w-50' style={style1} rel='noreferrer' href='https://docs.google.com/forms/d/e/1FAIpQLSe0k4fkWxHOEFTZfrCPLfFxWWdkoKgCrwPEYr83UfSfLKS6NA/viewform?usp=sf_link' target='_blank'>Join Our Team</a>
                            </div>
                        </div>
                        <div className=' d-flex justify-content-center'>
                            <img className='' style={Imagestyle} src={headerBanner} alt='HeaderBanner' />
                        </div>

                        <div className='d-flex justify-content-center align-items-end'>
                            <div className=' justify-content-center   mt-5 ' style={style3}>
                                <button onClick={() => {
                                        console.log('jjjjjjjjjjjjj')

                                    let targetOffset = document.getElementById('contact').offsetTop - 98;
                                    window.scrollTo({ top: targetOffset, behavior: 'smooth' });
                                }
                                }
                                    className=' border-0 d-flex justify-content-center pt-2 pb-2 rounded rounded-5 text-decoration-none  text-white btn1 w-100' style={style1} href='#contact'>Get Started</button>
                                <a className='d-flex justify-content-center pt-2 pb-2 rounded rounded-5 text-decoration-none  text-primary btn2  ms-sm-5 w-100' style={style1} rel='noreferrer' href='https://docs.google.com/forms/d/e/1FAIpQLSe0k4fkWxHOEFTZfrCPLfFxWWdkoKgCrwPEYr83UfSfLKS6NA/viewform?usp=sf_link' target='_blank'>Join Our Team</a>
                            </div>
                        </div>

                    </div>
                    <div className='justify-content-center  d-flex ' style={style5}>

                        <ScrollDown />
                    </div>
                </MyContainer>


            </div>

        </StyleRoot>
    )
}

export default PageContainerMain