import React from 'react'
// import { Outlet } from 'react-router-dom'
import PageContainerMain from './PageContainer/PageContainerMain/PageContainerMain'
import OurServices from '../features/project/ServiceLeft/OurServices'
import OurTeam from './PageContainer/OurTeam/OurTeam'
import OurValue from './PageContainer/OurValue/OurValue'
import PortFolio from './PageContainer/PortFolio/PortFolio'
import Signin from '../pages/Signin/Signin'
import { Container } from 'react-bootstrap'
const Home = () => {
    return (
        <div className='overflow-hidden'>
             <OurServices />
            <OurTeam />
           <OurValue />
             <PortFolio />
            <Signin />
        </div>
    )
}

export default Home