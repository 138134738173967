import './AboutUs.css'
import React from 'react'
import imgMarmaz from '../../assets/images/img-marmaz.png'
import imgHand from '../../assets/images/img-hand.png'
import MyContainer from '../../components/MyContainer'
import Radium, { StyleRoot } from 'radium';
const AboutUs = () => {
    const img1 = {
        width: '51.157vw',
        height: '33.738vw',
        // backgroundImage: `url(${imgMarmaz})`,
        // backgroundSize: "revert",
        // backgroundRepeat: 'no-repeat',

        '@media (max-width: 428px)': {
            width: '100%',
            height: '110.047vw'
        },
        '@media (max-width: 834px)(min-width:428px)': {
            width: '58.993vw',
            height: '37.410vw'
        },
    };

    const img2 = {
        width: '27.546vw',
        height: '33.738vw',
        '@media (max-width: 428px)': {
            width: '100%',
            height: '63.785vw',
            paddingTop: 10,
        },
        '@media (max-width: 834px)(min-width:428px)': {
            width: '31.415vw',
            height: '37.410vw'
        },

    };


    const style = {
        fontWeight: 700,
        fontSize: '4.342vw',
        lineHeight: '4.776vw',
        font: 'DIN Next LT Arabic',
        // Adding media query..
        '@media (max-width: 834px)': {
            fontSize: '4.796vw',
            lineHeight: '4.776vw',

        },
        '@media (max-width: 428px)': {
            fontSize: '6.796vw',
            lineHeight: '5.276vw',
        },

    };

    const style1 = {
        fontWeight: 400,
        fontSize: '1.390vw',
        lineHeight: '2.043vw',
        font: 'DIN Next LT Arabic',
        // Adding media query..
        '@media (max-width: 834px)': {
            fontSize: '2.398vw',
            lineHeight: '3.525vw',

        },
        '@media (max-width: 428px)': {
            fontSize: '4.673vw',
            lineHeight: '6.869vw',
        },

    };

    const style2 = {
        fontWeight: 700,
        fontSize: '2.895vw',
        lineHeight: '3.185vw',
        font: 'DIN Next LT Arabic',
        // Adding media query..
        '@media (max-width: 834px)': {
            fontSize: '3.597vw',
            lineHeight: '3.957vw',

        },
        '@media (max-width: 428px)': {
            fontSize: '7.411vw',
            lineHeight: '12.364vw',
        },

    };

    const style3 = {
        fontWeight: 400,
        fontSize: '1.158vw',
        lineHeight: '1.702vw',
        font: 'DIN Next LT Arabic',
        // Adding media query..
        '@media (max-width: 834px)': {
            fontSize: '1.918vw',
            lineHeight: '2.820vw',

        },
        '@media (max-width: 428px)': {
            fontSize: '3.738vw',
            lineHeight: '5.495vw',
        },

    };
    const s = {
        display: 'flex',
        '@media (max-width: 428px)': {
            display: 'block'
        },
    }

    const style4 = {
        // paddingTop:'10.944vw',
        marginTop: 150,
        paddingBottom: '6.944vw',
        '@media (max-width: 834px)': {
            // paddingTop:'22.194vw',
            paddingBottom: '13.909vw',

        },
        '@media (max-width: 428px)': {
            // paddingTop:'45.047vw',
            paddingBottom: '35.748vw',
        },
        '@media (max-width: 990px)': {
            // paddingTop:'13.194vw',
            paddingBottom: '13.909vw',

        },
    };
    return (
        <StyleRoot>
            <MyContainer>
                <div id='about' className='overflow-hidden' style={style4}>
                    <div className='about-title'>
                        <h1 className='text-primary' style={style}>About Our Company</h1>
                        <p style={style1}>We Have Another Flavor For Solutions.</p>
                    </div>
                    <div className=' justify-content-between ' style={s}>
                        <img className='d-flex justify-content-center' src={imgMarmaz} style={img1} alt='' />
                        <img className='' src={imgHand} style={img2} alt='' />
                    </div>
                    <div className='about-us d-block d-sm-flex justify-content-center'>
                        <h1 className='w-75 text-primary' style={style2}>What We Do</h1>
                        <div className='about-right' style={style3}>
                            <p >We transform ideas into functional digital solutions through skilled programming and captivating design.
                            </p>
                            <p>
                                By collaborating closely with clients, we craft innovative experiences that leave a lasting impact.
                                From web development to emerging technologies, we're your partners in navigating the digital landscape
                            </p>
                        </div>
                    </div>
                </div>
            </MyContainer>
        </StyleRoot>
    )
}

export default AboutUs

//Content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.