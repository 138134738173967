import React from 'react'


import ESSImg from '../../../assets/images/Ess.png'
import JappanImg from '../../../assets/images/Aljappan.jpg'
import Lynmouth from '../../../assets/images/Lynemouth.png'
import upTechImg from '../../../assets/images/uptech.jpg'
import YourEyesImg from '../../../assets/images/YourEyes.jpg'
import MyContainer from '../../../components/MyContainer'
import FirstTilte from '../../../components/FirstTitle'
import SectionTilte from '../../../components/SectionTitle'
import Description from '../../../components/Discription'
import Radium, { StyleRoot } from 'radium'; 

import ScrollContainer from "react-indiana-drag-scroll";

const PortFolio = () => {
    function Card(Img,Title,Des){
        const style = {
            fontWeight:500,
            fontSize:'1.5vw',
            lineHeight:'2.025vw',
            font:'DIN Next LT Arabic',
            height:'3vw',
        // Adding media query..
        '@media (max-width: 428px)': {
          'font-size': '5.5vw',
          lineHeight:'6.776vw',
          height:'11vw'

        },
        '@media (max-width: 834px)': {
            'font-size': '2.878vw',
            lineHeight:'4.197vw',
            height:'7.5vw'
          },

      };
      const style1 = {
        fontWeight:400,
        fontSize:'1.158vw',
        lineHeight:'1.679vw',
        font:'DIN Next LT Arabic',
        height:29,
    // Adding media query..
    '@media (max-width: 834px)': {
        fontSize:'1.918vw',
        lineHeight:'2.820vw',
    },
    
    '@media (max-width: 428px)': {
        fontSize:'3.738vw',
        lineHeight:'5.495vw',
    },
  };

  const style2 = {
    borderRadius:40,
    width:'23.161551823972vw',
     height:'18.760856977417vw' ,
     backgroundImage: "linear-gradient(rgba(123, 66, 210, 0.3),rgba(123, 66, 210, 0) )",
     '@media (max-width: 834px)': {
        width:'30.935vw',
        height:'25.060vw' ,
      },
      '@media (max-width: 428px)': {
        width:'60.280vw',
        height:'48.832vw' ,
      },
};
        return(
            <div className='me-4'>
                <div className='mt-3 d-flex justify-content-center align-items-center' style={style2}>
    
                <img src={Img} width={'100%'} height={'100%'} style={{  'mixBlendMode': 'multiply',borderRadius:40,}}  alt='' className='img-2' />

                </div>
                <StyleRoot>
                <h3 className='  text-primary text-center text-capitalize mt-3' style={style}>{Title}</h3>
                <p className=' text-center text-success d-flex align-items-center justify-content-center' style={style1}>{Des}</p>
                </StyleRoot>
        </div>
        )
    }
    return (
        <div className='overflow-hidden' id='ourwork'>
            <div style={{marginTop:60}} >
            <MyContainer>
                <FirstTilte
                Title='Have a look to'
                />
                {/* <p className='text-success' style={{fontWeight:400,fontSize:20}}>Have a look to</p> */}
                <SectionTilte
                Title='Our Work and Portfolio'
                />
                {/* <h1 className='text-primary' style={{fontWeight:700,fontSize:'2rem'}}>Our Work and Portfolio</h1> */}
                <div className=' row col-12 col-lg-6 '>
                    <Description
                    Description='View our diverse portfolio showcasing solutions crafted for global clients.See how we have helped ambitious brands achieve next-level results.'
                    />
                {/* <p className='col-12 col-lg-6 text-success' style={{fontWeight:400,fontSize:'1rem'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lorem arcu adipiscing quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p> */}
                </div>
                <ScrollContainer className='d-flex'  vertical={false} horizontal={true} >
                {Card(JappanImg,'Aljabban Builder','Where Vision Meets Reality')}
                {Card(Lynmouth,'Lynemouth Car Services','Drive Fresh Ride Smooth')}
                {Card(YourEyesImg,'Your Eyes Solutions','No fear any more, we\'re your eyes.')}
                {Card(ESSImg,'Electronic Equipment Services','Expert Care For Printing')}

                {Card(upTechImg,'Uptech','Keep Your Business Up')}


                {/* <div>
                    <div className='card-port'>
                        <img src={ESSImg} alt='' />
                        <div className='text'>
                            <h3>Electronic Equipment Services</h3>
                            <p>Expert Care For Printing</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='card-port'>
                        <img src={JappanImg} alt='' className='img-2' />
                        <div className='text-2'>
                            <h3>ALJABBAN BUILDER</h3>
                            <p>Where Vision Meets Reality</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='card-port'>
                        <img src={Lynmouth} alt='' />
                        <div className='text'>
                            <h3>LYNEMOUTH CAR SERVICES</h3>
                            <p>Drive Fresh Ride Smooth </p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='card-port'>
                        <img src={upTechImg} alt='' />
                        <div className='text'>
                            <h3>UPTECH</h3>
                            <p>Keep Your Business Up</p>
                        </div>
                    </div>
                </div> */}
                
            </ScrollContainer>
            </MyContainer>
            </div>
        </div>
    )
}

export default PortFolio
