import React from "react";
import Radium, { StyleRoot } from 'radium';

export default function Description(props){
    const style = {
        fontWeight:400,
        fontSize:20,
        lineHeight:1.5,
        font:'DIN Next LT Arabic',
    // Adding media query..
    '@media (max-width: 428px)': {
        fontSize:16,lineHeight:1.5
    },

    '@media (max-width: 834px)': {
        fontSize:20,lineHeight:1.5
    },
    
  };
    return(
        <StyleRoot>
        <h1 className={`${props.Class} text-light `} style={style}>{props.Description}</h1>
        </StyleRoot>
    )
}