import React from 'react'
import MyContainer from '../../../components/MyContainer'
import FirstTilte from '../../../components/FirstTitle'
import SectionTilte from '../../../components/SectionTitle'
import Description from '../../../components/Discription'
import Radium, { StyleRoot } from 'radium';

const OurValue = () => {
    function Value(order, title, des) {
        const style = {
            fontWeight: 700,
            fontSize: 50,
            lineHeight: 1.5,
            font: 'DIN Next LT Arabic',
            // Adding media query..
            '@media (max-width: 834px)': {
                'font-size': 32,
            },

        };


        const style1 = {
            width: '21.598147075854vw',
            height: ' 12.159814707585vw',
        };
        return (
            <div className='value d-block col-lg-4 col-md-6 col-12 mt-4 '  >

                <StyleRoot>
                    <div className=''>
                        <h1 style={style}>{order}</h1>
                        <h2 style={{ fontSize: 30, fontWeight: 700, lineHeight: 1.5, font: 'DIN Next LT Arabic', }}>{title}</h2>
                    </div>
                    <Description
                        Description={des}
                    />
                </StyleRoot>
            </div>

        )
    }
    return (
        <div className='overflow-hidden' id='ourvalue'>
            <div style={{ marginTop: 50 }}  >
                <MyContainer>
                    <FirstTilte
                        Title='Know better about'
                    />
                    <SectionTilte
                        Title='OUR VALUES'
                    />
                    <div className='row'>
                        <Description
                            Description='We believe that creating digital products should not take away the essence of human nature.'
                        />
                    </div>
                    <div className='row'>
                        {Value('01', 'Passion', 'Building solutions that truly make a difference,fueled by our collective passion.')}
                        {Value('02', 'Simplicity', 'We believe less is more.Transparency through simplicity.')}
                        {Value('03', 'Quality', 'Delivering excellence throughthe relentless pursuit of perfection.')}
                        {Value('04', 'UX', 'Cutting-edge UX design ensures intuitive, impactful experiences that delight your customers.')}
                        {Value('05', 'Precision', 'We deliver solutions with meticulous precision through quality-focused development and testing practices.')}
                        {Value('06', 'Dedication', 'Our dedicated experts work tirelessly to bring ideas to life with resourcefulness, care and commitment to your goals.')}
                    </div>
                </MyContainer>
            </div>

        </div>

    )
}

export default OurValue