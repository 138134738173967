import React from 'react'
import Radium, { StyleRoot } from 'radium';

const ScrollDown = () => {

    const scrollToElement = () => {
        const navbarHeight = document.getElementById('nav').offsetHeight;
        const targetOffset = document.getElementById('services').offsetTop - 100;
        window.scrollTo({ top: targetOffset, behavior: 'smooth' });
      };


      
    return (
        <StyleRoot>
        <div className=' d-none d-md-flex text-black'>
            <button onClick={scrollToElement} className='border-0 bg-transparent text-decoration-none ' href='#services'>
                <p className='text-dark fs-6' style={{fontWeight:550,fontSize:20,font:'DIN Next LT Arabic'}}>
                    Scroll Down
                    <i className='d-flex justify-content-center fas fa-angle-down'></i></p>
            </button>
        </div>
        </StyleRoot>
    )
}

export default ScrollDown