import React from 'react'

import Radium, { StyleRoot } from 'radium';

const OurServicesRight = (props) => {


    const style = {
        width:300 ,  height:344,
    // Adding media query..
    '@media (max-width: 834px)': {
      'margin-top': 50,
    },
    
  };
    return (
        <StyleRoot>

                <div id={props.Id} className={`${props.Class} pt-5 pb-5 `} style={style}
                onClick={props.OnClick}>
                    <img src={props.Img} alt='' style={{paddingLeft:50,paddingRight:50,paddingBottom:32}}/>
                    <h3 className='pt-2 text-primary ' style={{fontWeight:500, font:'DIN Next LT Arabic',lineHeight:1.8,fontSize:20,paddingLeft:47,paddingRight:47}}>{props.Title}</h3>
                    <p className='pt-2 text-light'  style={{fontWeight:400, font:'DIN Next LT Arabic',lineHeight:1.5,fontSize:16,paddingLeft:47,paddingRight:47}}>{props.Desription}</p>
                </div>
                </StyleRoot>


    )
}

export default OurServicesRight