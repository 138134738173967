import React, { useState } from 'react'
import vector from '../../../assets/images/Vector.png'
import OurServicesRight from '../ServiceRight/OurServicesRight'
import webIcon from '../../../assets/images/web-design-svgrepo-com 1.png'
import browserIcon from '../../../assets/images/browser-web-development-svgrepo-com 1.png'
import appIcon from '../../../assets/images/app-svgrepo-com 1.png'
import MyContainer from '../../../components/MyContainer'
import SectionTilte from '../../../components/SectionTitle'
import FirstTilte from '../../../components/FirstTitle'
import Description from '../../../components/Discription'
import ScrollContainer from "react-indiana-drag-scroll";
import Radium, { StyleRoot } from 'radium';

const OurServices = () => {
    const [selectedCard, setSelectedCard] = React.useState(0);
    const handleRightClick = () => {
        setSelectedCard(old => old + 1 < data.length ? old + 1 : 0);
        const element = document.getElementById(selectedCard + 'Ser');

        element.scrollTo({ left: 0, behavior: 'smooth' });
    };
    const [data, setData] = useState([
        {
            Title: 'Web Development',
            Desription: 'Bring to the table survival strategies to ensure proactive domination',
            Img: webIcon

        },
        {
            Title: 'Apps Development',
            Desription: 'Grow the holistic world view of disruptive innovation workspace',
            Img: browserIcon
        },
        {
            Title: 'UI/UX Designing',
            Desription: 'Leverage agile framework to provide a robust high level synopsys overviews',
            Img: appIcon
        },
    ])

    const style5 = {
        display: 'flex',
        top: 250, right: '7.722vw',
        '@media (max-width: 1150px)': {
            display: 'none',

        },
    }

    return (
        <StyleRoot>
        <div className='position-relative pb-5 bg-secondary overflow-hidden' id='services' style={{ paddingTop: 100 }}>
            <MyContainer >
                <div className='row  d-flex justify-content-between' >
                    <div className='col-lg-4'>
                        <div className=''>
                            <FirstTilte
                                Title='Take a look to'
                            />
                            <SectionTilte
                                Class='pb-4'

                                Title='Our Services'
                            />
                            <Description
                                Description='We will bring the breathe of our experience and industry knowledge to help you succeed'
                            />
                        </div>
                        <div className='d-lg-flex justify-content-end d-none '>
                            <img src={vector} alt='vector' />
                        </div>
                    </div>
                    <ScrollContainer className='col-lg-8 col-12 d-flex justify-content-between align-items-center ' vertical={false} horizontal={true} >

                        {
                            data.map((item, index) =>

                                <OurServicesRight
                               
                                    Id={index + 'Ser'}
                                    Class={`${selectedCard == index ? 'shadow shadow-3 bg-white   ' : 'bg-secondary'}`}
                                    {...item}
                                    OnClick={() => setSelectedCard(index)} />
                            )
                        }

                    </ScrollContainer>

                </div>
            </MyContainer>
            <i className='position-absolute ms-5 fas fa-angle-right fs-3 d-flex align-items-center'
            style={style5}
                id='arrow-right'
                onClick={handleRightClick}></i>

        </div>
        </StyleRoot>
    )
}

export default OurServices
