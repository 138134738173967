import React, { useState, useLayoutEffect } from 'react'
import Radium, { StyleRoot } from 'radium';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/images/Logo.svg'
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
const Header = (props) => {
    const navigate = useNavigate();
    const [scrollPosition, setPosition] = useState(0);
    const [control, setControl] = useState(
        {
            expanded: false,
            CurrentLanguage: 'English',
            ActiveIndex: 0,
            Links: [
                {
                    Label: 'Home',
                    Href: 'home',
                },
                {
                    Label: 'Our Services',
                    Href: 'services',
                },
                {
                    Label: 'Our Value',
                    Href: 'ourvalue',
                },
                {
                    Label: 'Our Work',
                    Href: 'ourwork',
                },
                {
                    Label: 'Contact Us',
                    Href: 'contact',
                },
                {
                    Label: 'About Us',
                    Href: 'about',
                }
            ]
        }
    )
    useLayoutEffect(() => {
        function updatePosition() {
            setPosition(window.pageYOffset);
        }
        window.addEventListener('scroll', updatePosition);
        updatePosition();
        return () => window.removeEventListener('scroll', updatePosition);
    }, []);


    // useEffect(()=>{
    //     scrollToElement(control.Links[control.ActiveIndex].Href)
    // })


    const scrollToElement = (id) => {
        let targetOffset = document.getElementById(id).offsetTop - 98;
        targetOffset -= id == 'about' ? 150 : 0
        window.scrollTo({ top: targetOffset, behavior: 'smooth' });
    };


    const style = {
        marginLeft:scrollPosition>0?'8vw':'3vw',
        width: '12.002vw',
        '@media (max-width:428px)': {
            width: '50vw',
            marginLeft:0


        },
        '@media (max-width: 834px)': {
            width: '30.355vw',
            marginLeft:scrollPosition>0?'3vw':'3vw',


        }
    }

    const style1 = {
        borderTop: 'none ',
        marginTop: 0,

        '@media (max-width: 991px)': {
            borderTop: '1px solid rgb(153, 153, 153,0.5)',
            marginTop: 20


        }
    }
    return (
        <StyleRoot>

            <div id='nav' className='d-flex justify-content-center  ' style={{ transition: '0.7s', paddingTop: scrollPosition > 0 ? 0 : 50, }}>
                <Navbar expanded={control.expanded} expand="lg"
                    style={{
                        transition: '0.7s',
                        zIndex: 1,
                        borderRadius: scrollPosition > 10 ? 0 : 10,
                        width: scrollPosition > 10 ? '100%' : '90%',
                    }}
                    className={`  position-fixed ps-3 pe-3 bg-body shadow shadow-3 border `}
                >
                    <Container fluid className='pt-3 pb-3  d-flex  ' >

                        <Navbar.Brand className='d-flex align-items-center' href="#home">
                            <img
                            onClick={()=>
                                {
                                    setControl(old => ({ ...old, ActiveIndex: 0, expanded: false }))
                                    props.SetId('home')
                                    props.Control(false )
                                }}
                                src={logo}

                                alt='Logo'
                                style={{ transition: '0.7s', ...style }}
                            />
                        </Navbar.Brand>

                        <Navbar.Toggle
                            onClick={() => setControl(old => ({ ...old, expanded: !old.expanded }))}
                            aria-controls="basic-navbar-nav"
                            className='d-flex d-lg-none justify-content-center align-items-center'
                            style={{ width: 50, height: 50 }}
                        />

                        <Navbar.Collapse className="justify-content-center " >
                            <div style={style1} >
                                <Nav  >
                                    {
                                        control.Links.map((L, index) =>
                                            <button key={index}
                                                className={` d-flex align-items-center  justify-content-center   pe-4 mt-3 mt-lg-0 bg-transparent border-0  text-${props.ActiveIndex == index ? 'primary   fw-bold ' : 'light'} `}
                                                style={{ transition: '0.7s', fontWeight: 400 }}
                                                href={L.Href}
                                                onClick={() => {
                                                    // L.Href=='about'?navigate('/about'):
                                                    // navigate('/')
                                                    // scrollToElement(L.Href)
                                                    setControl(old => ({ ...old, ActiveIndex: index, expanded: false }))
                                                    props.SetId(L.Href)
                                                    props.Control(L.Href=='about'?true:false,index )
                                                }}
                                            >
                                                {L.Label}
                                            </button>
                                        )
                                    }
                                </Nav>
                            </div>
                        </Navbar.Collapse>

                    </Container>

                </Navbar>

            </div>

        </StyleRoot>
    )
}

export default Header
