import React, { useState,useEffect } from 'react'
import logo from '../../assets/images/logoWhite.png'
import { BiLogoFacebook } from 'react-icons/bi'
import { TiSocialLinkedin } from 'react-icons/ti'
import { FaWhatsapp } from 'react-icons/fa'
import { FiTwitter } from 'react-icons/fi'
import { AiOutlineBehance } from 'react-icons/ai'
import { LiaAngleRightSolid } from 'react-icons/lia'
import { Container } from 'react-bootstrap'
import Radium, { StyleRoot } from 'radium';
import Description from '../../components/Discription'
import MyContainer from '../../components/MyContainer'
import Twitter from '../../assets/Twitter.svg'
import Twitter1 from '../../assets/Twitter1.svg'
import arrowRight from '../../assets/images/arrow-right.svg'
import emailjs from "@emailjs/browser";
import Dialog from '../../components/Dialog';

const Footer = (props) => {




    const [formData, setFormData] = React.useState({
        email: ""
    })
    const [showDialog, setDialog] = React.useState(false)

    const [Tw, setTwitter] = React.useState(0)

    function handleChange(e) {
        const { name, value } = e.target
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }))
    }

    const style = {
        background: '#45296A', paddingTop: 100,
        paddingBottom: 50,
        // Adding media query..
        '@media (max-width: 764px)': {
        },

    };

    const style1 = {
        width: 250,
        '@media (max-width: 355px)': {
            width: '100%'
        },
    };

    const style2 = {

paddingLeft:10,

        height: 60,
        fontWeight: 400, lineHeight: 1.5, font: 'DIN Next LT Arabic',
        fontSize: 20,
        '@media (max-width: 428px)': {
            height: 60,

            fontSize: 20, lineHeight: '6.869vw'
        },

        '@media (max-width: 834px)': {
            height: 60,

            fontSize: 20, lineHeight: '3.525vw'
        },
    };

    const style5 = {

        paddingLeft:10,
        
                height: 'auto',
                fontWeight: 400,  font: 'DIN Next LT Arabic',
                fontSize: 20,
                '@media (max-width: 428px)': {
        
                    fontSize: 20,
                },
        
                '@media (max-width: 834px)': {
        
                    fontSize: 20,
                },
            };

    const style4 = {
        top: '55%', right: 10, borderRadius: 10,
        height: 40,
        width: 40,
        '@media (max-width: 428px)': {
            height: 40,
            width: 40,
        },

        '@media (max-width: 834px)': {
            height: 40,
            width: 40,
        },
    };
    const style3 = {
        lineHeight: '1.955vw', font: 'Dubai', height: 40, fontWeight: 400,
        fontSize: '1vw',
        '@media (max-width: 428px)': {
            fontSize: '4.8vw', lineHeight: '7.888vw'
        },

        '@media (max-width: 834px)': {
            fontSize: '3.3vw', lineHeight: '4.048vw'
        },

        '@media (max-width: 1500px)': {
            fontSize: '1.5vw', lineHeight: '7.888vw'
        },

    };



    const [alert, setAlert] = useState('')

    async function handleSubmit() {
        if (!formData.email || formData.email.trim().length === 0) {

            setAlert('email is required')
            setTimeout(() => {
                setAlert('');
            }, 5000);
            return
        }
        else if (!String(formData.email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
            setAlert('this is not email address')
            setTimeout(() => {
                setAlert('');
            }, 5000);
            return
        }
        setAlert('')
        const serviceId = "116713044124290808716";
        const templateId = "template_96qgjpv";
        try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                to_name: "Marmaze",
                from_name: 'MARMAZE WEB SITE',
                message: 'NO MESSAGE',
                from: formData.email,
                Subject: 'Subscribe in News'
            });
            setDialog(true)

        } catch (error) {
            setFormData({ email: '' })
            setLoading(false);

        } finally {
            setFormData({ email: '' })
            setLoading(false);
        }
    }
    const [loading, setLoading] = useState(false);

    return (
        <StyleRoot>
            <div className='' style={style}>

                <MyContainer>
                    <div className='row  d-flex justify-content-between overflow-hidden'>
                        <div className='col-12 col-lg-4 mb-5 mb-lg-0'>
                            <img className='row ' src={logo} alt='' width={190} height={40} />
                            <Description Class='row text-light mt-3' Description='MarmazTech brings bold visions to life,With dedicated teams,precision solutions,your success is our priority.' />
                            {/* <p className=''>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the</p> */}
                            <div className=' d-flex mt-4  justify-content-between  ' style={style1}>
                                <a href='https://www.facebook.com/marmaztech' style={{ width: 40, height: 40 }} className=' Social rounded- rounded-5 d-flex justify-content-center align-items-center' target='_blank' rel='noreferrer'><BiLogoFacebook style={{ width: 20, height: 20 }} className='fb ' /></a>
                                <a href='https://www.linkedin.com/company/marmaztech/' style={{ width: 40, height: 40 }} className=' Social rounded- rounded-5  d-flex justify-content-center align-items-center' target='_blank' rel='noreferrer'><TiSocialLinkedin style={{ width: 20, height: 20 }} className='li' /></a>
                                {/* <a href='https://www.whatsapp.com' style={{ width: 40, height: 40 }} className=' Social rounded- rounded-5  d-flex justify-content-center align-items-center' target='_blank' rel='noreferrer'><FaWhatsapp style={{ width: 20, height: 20 }} className='wa' /></a> */}
                                <a href='https://twitter.com/MarmazTech' style={{ width: 40, height: 40 }} className=' Social rounded- rounded-5 d-flex justify-content-center align-items-center' target='_blank' rel='noreferrer' onMouseOver={() => setTwitter(1)} onMouseLeave={() => setTwitter(0)}><img src={Tw == 0 ? Twitter : Twitter1} style={{ width: 15, height: 15 }} className='' /></a>
                                <a href='https://www.behance.net/marmaztech' style={{ width: 40, height: 40 }} className='Social rounded- rounded-5  d-flex justify-content-center align-items-center' target='_blank' rel='noreferrer'><AiOutlineBehance style={{ width: 20, height: 20 }} className='be' /></a>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4  mb-5 mb-lg-0'>
                            <div className='row text-white'>
                                <h2 style={{ fontSize: 24, fontWeight: 500, lineHeight: 1.5, font: 'DIN Next LT Arabic' }}>Site Map</h2>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <p><button style={style5} className='bg-transparent border-0 text-light text-decoration-none' onClick={()=>{
                                                    props.SetId('about')
                                                    props.Control(true,5 )
                                                    
                                    }}>About Us</button></p>
                                    <p><button style={style5} className='bg-transparent border-0 text-light text-decoration-none'  onClick={()=>{
                                                    props.SetId('contact')
                                                    props.Control(false,4 )

                                    }}>Contact Us</button></p>
                                    <p><button style={style5} className='bg-transparent border-0 text-light text-decoration-none'  onClick={()=>{
                                        window.open('https://docs.google.com/forms/d/e/1FAIpQLSe0k4fkWxHOEFTZfrCPLfFxWWdkoKgCrwPEYr83UfSfLKS6NA/viewform?usp=sf_link')
                                                    // props.SetId('contact')
                                                    // props.Control(false,4 )

                                    }}>Join Us</button></p>
                                </div>
                                <div className='col-6'>
                                    <p><button style={style5} className='bg-transparent border-0 text-light text-decoration-none' onClick={()=>{
                                                    props.SetId('services')
                                                    props.Control(false,1 )

                                    }}>Our Services</button></p>
                                    <p><button style={style5} className='bg-transparent border-0 text-light text-decoration-none'  onClick={()=>{
                                                    props.SetId('ourvalue')
                                                    props.Control(false,2 )

                                    }}>Our Value</button></p>
                                    <p><button style={style5} className='bg-transparent border-0 text-light text-decoration-none'  onClick={()=>{
                                                    props.SetId('ourwork')
                                                    props.Control(false,3 )

                                    }}>Our Works</button></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-sm-8 col-lg-4'>
                            <div className='row text-white'>
                                <h1 style={{ fontSize: 24, fontWeight: 500, lineHeight: 1.5, font: 'DIN Next LT Arabic' }}>Join Our Newsletter</h1>
                            </div>
                            <div className='position-relative  d-block text-light '>
                                <span className='d-flex'><p style={{ fontSize: 20, fontWeight: 500, lineHeight: 1.5, font: 'DIN Next LT Arabic' }}>Your email </p> <p className={`ms-auto `} style={{ color: 'red' }}>{alert}</p></span>
                                <input className='w-100 text-light rounded rounded-3 bg-info border border-light'
                                    style={style2}
                                    type='email' name='email' placeholder='  example@yourmail.com'
                                    value={formData.email} onChange={handleChange} />
                                <span style={{ top: 52, right: 10 }}
                                    className={`${loading ? 'd-flex' : 'd-none'}  position-absolute mt-2 spinner-border   text-primary`} role="status" aria-hidden="true"></span>

                                <img src={arrowRight} alt=''
                                    onClick={() => handleSubmit()}
                                    style={style4}
                                    className={`${loading ? 'd-none' : 'd-flex'}  position-absolute `} />
                            </div>
                        </div>
                    </div>

                </MyContainer>
            </div>
            <div className='bg-primary text-light d-flex justify-content-center align-items-center pt-4 pb-4'
                style={style3}>
                MARMAZTECH LTD All Rights Reserved. © {new Date().getFullYear()}
            </div>

            <Dialog Show={showDialog} Close={()=>setDialog(false)} Title='You have been subscribed to the newsletter' Body='Thank You For Your Contact'/>

        </StyleRoot>
    )
}

export default Footer