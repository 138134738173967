import React from 'react'
import FormUi from '../../components/FormUI/FormUi'
import Mail from '../../assets/images/Mail.svg'
import Call from '../../assets/images/call.svg'
import Radium, { StyleRoot } from 'radium';

const Signin = () => {
    const style = {
        width: '90.966994788651vw', height: '21.424435437174vw', borderRadius: 20, marginTop: 70,
        // Adding media query..
        '@media (max-width: 582px)': {
            'margin-left': 0,
            'margin-right': 0,
            height: 200,
            borderRadius: 0,
            width: '100%'
        },

    };

    const style1 = {
        fontWeight: 400,
        fontSize: 20,
        lineHeight: 1.5,
        font: 'DIN Next LT Arabic',
        '@media (max-width: 1300px)': {
            fontSize: 13,

        },
        '@media (max-width: 362px)': {
            fontSize: 10,

        },
    };

    const style2 = {
        fontWeight: 700,
        fontSize: 64,
        lineHeight: 1.5,
        paddingTop: 40,
        font: 'DIN Next LT Arabic',
        '@media (max-width: 1300px)': {
            fontSize: 30,
            paddingTop: 20

        },

        '@media (max-width: 362px)': {
            fontSize: 20,
            paddingTop: 10

        },
    }

    const style3 = {
        height: 1000, paddingTop: 20, marginTop: 50,
        // Adding media query..

        '@media (min-width: 400px)': {
            'height': 1050,
        },
        '@media (min-width: 500px)': {
            'height': 1100,
        },
        '@media (min-width: 600px)': {
            'height': 1150,
        },
        '@media (min-width: 768px)': {
            'height': 1000,
        },
        '@media (min-width: 834px)': {
            'height': 950,
        },
        '@media (min-width: 1160px)': {
            'height': 1000,
        },
        '@media (min-width: 1301px)': {
            'height': 1150,
        },
        '@media (min-width: 1550px)': {
            'height': 1200,
        },
        '@media (min-width: 1727px)': {
            'height': 1250,
        },
        '@media (min-width: 1900px)': {
            'height': 1300,
        },
        '@media (min-width: 2100px)': {
            'height': 1500,
        },

    };

    const style4 = {
        paddingTop: 30,
        display: 'flex',
        '@media (max-width: 923px)': {
            paddingTop: 5

        },
        '@media (max-width: 428px)': {
            display: 'block'

        }

    };


    const style5 = {
        width: '1.448vw', height: '1.448vw',
        '@media (max-width: 428px)': {
            width: '5.841vw', height: '5.841vw',


        },
        '@media (max-width: 834px)': {
            width: '2.998vw', height: '2.998vw',


        }


    };

    const style6 = {
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: 50,
        paddingLeft: 0,

        '@media (max-width: 428px)': {
            paddingTop: 10,
            paddingBottom: 10,
            marginLeft: 0,
            paddingLeft: 0,


        },


    };
    return (
        <StyleRoot>
            <div className='position-relative  bg-secondary  overflow-hidden' style={style3} id='contact'>
                <div className='d-flex justify-content-center'>
                    <div className=' bg-primary ' style={style}>
                        <h1 className='d-flex justify-content-center text-white ' style={style2}>Get in Touch</h1>
                        <div className='justify-content-center' style={style4}>
                            <div className='d-flex justify-content-center'>
                                <img src={Mail} alt='' style={style5} className='' />
                                <a className='text-white text-decoration-none ms-2 r' href='Info@marmaztech.com' style={style1}>Info@marmaztech.com</a>
                            </div>
                            <div className='  d-flex justify-content-center ' style={style6}>
                                <img src={Call} alt='' style={style5} />
                                <a className='text-white text-decoration-none ms-2  ' href='(+44)  7700 181 950' id='about' style={style1}>(+44)  7700 181 950</a>
                            </div>

                        </div>
                    </div>
                </div>
                <FormUi />
            </div>

        </StyleRoot>
    )
}

export default Signin