import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiFillCloseCircle } from 'react-icons/ai'
import Radium, { StyleRoot } from 'radium';

function Dialog(props) {
  const style={
    width:700,
    height:420
  }
  return (
    <StyleRoot>
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial', }}
    >
        <Modal className='' centered show={props.Show} size='lg' onHide={props.Close} style={{ direction:  "ltr" }}>
<AiFillCloseCircle  fill='#4B2684' style={{marginTop:20,marginLeft:20,height:30,width:30,borderRadius:50}} onClick={props.Close}/>

          <div style={{paddingLeft:20,paddingRight:20}}>
        <div className='mt-3 d-flex justify-content-center'>
          <Modal.Title className='fw-bold text-center' style={{fontSize:24}}>{props.Title}</Modal.Title>
        </div>
        <Modal.Body style={{fontSize:20,color:'#777777'}} className='d-flex justify-content-center'>
          <p className='text-center'> {props.Body}</p>
        </Modal.Body>
        <div className='d-flex mt-3 justify-content-center'> 
          <Button style={{height:50,width:100,fontSize:16}} className='mb-4 d-flex justify-content-center text-white bg-primary align-items-center' onClick={props.Close} variant="secondary">Done</Button>
          </div>
          </div>
      </Modal>
    </div>
    </StyleRoot>
  );
}

export default Dialog;