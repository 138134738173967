import React from "react";
import Radium, { StyleRoot } from 'radium';

export default function SectionTilte(props){
        const style = {
            fontWeight:700,
            fontSize:'2.085vw',
            lineHeight:'3.064vw',
            font:'DIN Next LT Arabic',
            paddingBottom:15,
        // Adding media query..
        '@media (max-width: 834px)': {
          'font-size': '3.597vw',
          lineHeight:'5.288vw',

        },
        '@media (max-width:428px)': {
            lineHeight:'10.304vw',
            'font-size': '7.009vw',
        },
      };
    return(
        <StyleRoot> 
        <h1 className={`text-primary ${props.Class}`} style={style}>{props.Title}</h1>
        </StyleRoot>
    )
}