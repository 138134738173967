import Navbar from "./layouts/NavBar/Navbar";
import Footer from "./layouts/Footer/Footer";
import PageContainerMain from "./layouts/PageContainer/PageContainerMain/PageContainerMain";
import Home from "./layouts/Home";
import AboutUs from "./pages/AboutUS/AboutUs";
import { useState,useEffect } from "react";
import { Route,Routes } from "react-router-dom";
function App() {
  const [control,setControl]=useState({
    About:false,
    ActiveIndex:0,
    Id:'home'
  })

  useEffect(() => {

      let targetOffset = document.getElementById(control.Id).offsetTop - 98;
      targetOffset -= control.Id == 'about' ? 150 : 0
      window.scrollTo({ top: targetOffset, behavior: 'smooth' });
  }, [control.Id]);
  return (
    <>
  <div style={{backgroundImage:`${!control.About? "linear-gradient(rgba(129, 80, 204, 0.2),rgba(75, 38, 132, 0.04) )":""}`}}>
      <Navbar Control={(value,ActiveIndex)=>{

        setControl(old=>({...old,About:value,ActiveIndex}))}
      }
      SetId={(id)=>setControl(old=>({...old,Id:id}))}
      ActiveIndex={control.ActiveIndex}
      />
      <div className={`${control.About?'d-none':'d-block'}`}>
      <PageContainerMain />
      </div>
      </div>
      <div className={`${control.About?'d-none':'d-block'}`}>
        <Home />
        </div>
        <div className={`${!control.About?'d-none':'d-block'}`}>
      <AboutUs />
      </div>
      <Footer Control={(value,ActiveIndex)=>{

setControl(old=>({...old,About:value,ActiveIndex}))}
} SetId={(id)=>setControl(old=>({...old,Id:id}))}
ActiveIndex={control.ActiveIndex}
/>
      </>


  // <>
  // <Routes>
  //          <Route path="/" element={ <>  <div style={{backgroundImage:`${!control.About? "linear-gradient(rgba(129, 80, 204, 0.2),rgba(75, 38, 132, 0.04) )":""}`}}>
  //       <Navbar Control={(value)=>setControl(old=>({...old,About:value}))}
  //       SetId={(id)=>setControl(old=>({...old,Id:id}))}
  //       />
  //       <PageContainerMain />
  //       </div>
  //         <Home /></>}>
  
  //            </Route>
  
  //            <Route path="/about" element={ <> 
  //       <Navbar Control={(value)=>setControl(old=>({...old,About:value}))}/>
  //       <AboutUs />
  //         </>}>
  
  //            </Route>
  
  //       </Routes> 
  //       <Footer />
  //       </>

      );
}

export default App;


{/*
    <>
<Routes>
         <Route path="/" element={ <>    <div style={{backgroundImage: "linear-gradient(rgba(129, 80, 204, 0.2),rgba(75, 38, 132, 0.04) )"}}>
      <Navbar />
      <PageContainerMain />
      </div>
        <Home /></>}>

           </Route>

           <Route path="/about" element={ <> 
      <Navbar />
      <AboutUs />
        </>}>

           </Route>

      </Routes> 
      <Footer />
      </>
*/}