import React from 'react'
// import './OurTeamLeft.css'
import { PiGearLight } from 'react-icons/pi'
import { RiTeamLine } from 'react-icons/ri'
import { AiOutlineUser } from 'react-icons/ai'
import FirstTilte from '../../../../components/FirstTitle'
import SectionTilte from '../../../../components/SectionTitle'
import Description from '../../../../components/Discription'
import Radium, { StyleRoot } from 'radium';

function IconAndText(order, Title, Des) {
    const style = {
        fontWeight: 500,
        fontSize: 24,
        lineHeight: 1.5,
        font: 'DIN Next LT Arabic',
        // Adding media query..
        '@media (max-width: 834px)': {
            'font-size': 20,
        },
    }

    const style1 = {
        marginRight:75,
        minWidth: 100, height: 100,
        '@media (max-width: 834px)': {
            marginRight:20,
        minWidth: 80, height: 80,

        },
    }
    const style2 = {
        height: 'auto',
        paddingBottom:20
        // Adding media query..
        // '@media (max-width: 834px)': {
        //     'margin-top': 20,
        //     'height': 126
        // },

        // '@media (max-width: 340px)': {
        //     'height': 170
        // },
        // '@media (max-width: 298px)': {
        //     'height': 250
        // },
    }
    return (
        <StyleRoot>
            <div className=' d-flex w-100' style={style2} >
                <div className='circle d-flex justify-content-center align-items-center ' style={{borderRadius: 50, ...style1}}>
                    {
                        order == 1 ?
                            <PiGearLight  style={{ width: '49.89%', height: '51.05%' }} /> :
                            order == 2 ?
                                <RiTeamLine  style={{ width: 45, height: 46 }} /> :
                                <AiOutlineUser  style={{ width: 45, height: 46 }} />
                    }      
                </div>
                <div className='  d-block'>
                    <h3 style={style} className='text-dark '>{Title}</h3>
                    <Description
                        Description={Des}
                    />
                    {/* <p style={style1} className='text-success'>{Des}</p> */}
                </div>
            </div>
        </StyleRoot>
    )
}
const OurTeamLeft = () => {
    return (
        <div className='d-block'>
            <FirstTilte
                Title='Why To Choose Us?'
            />
            <SectionTilte
                Title='Specialized In Providing The Best Technical Solutions For Our Clients'
            />
            <Description
                Class='pt-4 pb-5 '
                Description='Tailored technological expertise to empower your business growth.
            We deliver customized technical solutions focused on exceeding our clients needs.'
            />


            {/* <img src={GearWhite} alt='' /> */}

            {IconAndText(1, 'Working Process', 'Through agile methodology and iterative development, we deliver tailored solutions that evolve with your changing needs. Our collaborative process ensures on-time, on-budget results.')}

            {IconAndText(2, 'Dedicated Team', 'Our dedicated team is committed to your success. With experienced specialists assigned to each client, your projects receive uninterrupted support and expertise.')}

            {IconAndText(3, '24/7 Support', 'Need urgent assistance day or night? Our support network is available around the clock. You have direct access to assistance whenever your systems require support.')}

            {/* <div className='sections'>
                    <RiTeamLine className='users3' />
                    <div className='team-text'>
                        <h3>Dedicated Team</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lorem arcu adipiscing quis.</p>
                    </div>
                </div>
                <div className='sections'>
                    {/* <img src={Person} alt='' /> 
                    <AiOutlineUser className='user' />
                    <div className='team-text'>
                        <h3>24/7 Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lorem arcu adipiscing quis.</p>
                    </div>
                </div> */}
        </div>

    )
}

export default OurTeamLeft