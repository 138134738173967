import React from 'react'
import OurTeamLeft from './OurTeamLeft/OurTeamLeft'
import OurTeamRight from './OurTeamRight/OurTeamRight'
import MyContainer from '../../../components/MyContainer'
const OurTeam = () => {
    return (
            <MyContainer>
                <div className=' d-flex row justify-content-between' style={{marginTop:50  }}>
                    <div className='d-none d-lg-block col-12 col-md-5 col-lg-6' >
            <OurTeamLeft />
            </div>

            <div className='w-100 d-lg-none d-block '>
            <OurTeamLeft />
            </div>
            <div className='d-lg-flex justify-contnet-center align-items-center  d-none col-lg-6  col-xl-5    ' >
            <OurTeamRight />
            </div>

            </div>
            </MyContainer>
    )
}

export default OurTeam