import React, { useState, useEffect } from 'react'
import folderIcon from '../../assets/images/Folder.svg'
import Button from '../Button/Button'
import { Container } from 'react-bootstrap'
import Radium, { StyleRoot } from 'radium';
import emailjs from "@emailjs/browser";
import Dialog from '../Dialog';

const FormUi = () => {
    const style = {
        top: 350, borderRadius: 20,
        // Adding media query..
        '@media (max-width: 1300px)': {
            top: 240,
        },
        '@media (max-width: 900px)': {
            top: 210,
        },
        '@media (max-width: 428px)': {
            top: 230,
        },
        width: '81.065431383903vw',
        height: 'auto'


    }

    const style5 = {
        width: '1.448vw', height: '1.274vw',
        // Adding media query..
        '@media (max-width: 428px)': {
            width: '5.841vw', height: '5.140vw',
        },

        '@media (max-width: 834px)': {
            width: '2.998vw', height: '2.638vw',
        },
    }

    const style1 = {
        fontSize: '0.9vw',
        display: 'flex',
        marginLeft: 19,
        // Adding media query..
        // '@media (max-width: 428px)': {
        //     display: 'none',
        // },
        '@media (max-width: 1140px)': {
            display: 'none',
            width: 40,

        },
        '@media (max-width: 1500px)': {
            fontSize: '1vw',
        },

        '@media (max-width: 834px)': {
            width: 40,
        },
    }
    const style11 = {
        width: '32.1%',

        // Adding media query..
        '@media (max-width: 1140px)': {
            width: 33,
        },

    }


    const style2 = {
        paddingLeft: 76,
        paddingRight: 76,
        paddingTop: 66,
        paddingBottom: 47,
        '@media (max-width: 428px)': {
            paddingLeft: 19,
            paddingRight: 19,
            paddingTop: 30,
            paddingBottom: 40,
        },
        '@media (max-width: 834px)': {
            paddingLeft: 27,
            paddingRight: 27,
            paddingTop: 30,
            paddingBottom: 30,
        },

    }
    const style3 = {
        paddingBottom: '1.158vw',
        '@media (max-width: 834px)': {
            paddingBottom: '1.799vw'
        },
        '@media (max-width: 834px)': {
            paddingBottom: '3.505vw'
        },

    }

    const style4 = {
        borderRadius: 10, height: '4.632vw',
        // '@media (max-width: 600px)': {
        //     height: 40,
        // },
        '@media (max-width: 500px)': {
            height: 40,
        },
        '@media (max-width: 834px)': {
            height:50,
        },

    }

    const style6 = {
        height: '14.302vw',borderRadius: 10,  resize: 'none' ,
        // '@media (max-width: 600px)': {
        //     height: 40,
        // },
        '@media (max-width: 428px)': {
            height: '41.421vw',
        },
        '@media (max-width: 834px)': {
            height:'24.221vw',
        },

    }
    const [formData, setFormData] = React.useState({
        name: "",
        email: "",
        subject: "",
        upload: "",
        message: ""
    })
    const [formErrors, setFormErrors] = useState({
        name: "",
        email: "",
        subject: "",
        upload: "",
        message: ""
    });
    const [showDialog, setDialog] = React.useState(false)

    function handleChange(event) {
        const { name, value } = event.target
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        })
        )
    }
    async function handleSubmit() {
            if(validate('',true))
            return
            const serviceId = "116713044124290808716";
            const templateId = "template_96qgjpv";
            try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                to_name:"Marmaze",
            from_name: formData.name,
            message: formData.message,
            from:formData.email,
            Subject:formData.subject
            });

            setDialog(true)
            //   alert("email successfully sent");
            } catch (error) {
            setLoading(false);
            setFormData({
                name: "",
                email: "",
                subject: "",
                upload: "",
                message: ""

            })
            } finally {
            setLoading(false);
            setFormData({
                name: "",
                email: "",
                subject: "",
                upload: "",
                message: ""

            })
            }
    }

    function importData() {
        let input = document.createElement('input');
        input.type = 'file';
        input.onchange = _ => {
            //   let files =   Array.from(input.files);
        };
        input.click();

    }

    const validate = (field, all = false) => {
        const values = formData
        const errors = {
            name: "",
            email: "",
            subject: "",
            upload: "",
            message: ""
        };
        // if(!all)
        // {
        //     if (!values[field]||values[field].trim().length === 0){
        //         errors[field] = field+" is required!";

        //     }
        //     else if (field=='email'&&!String(values.email)
        //     .toLowerCase()
        //     .match(
        //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //     )) {
        //         errors.email='this is not email address'
        // }
        //     else{
        //         errors[field] = "";
        //     }

        // }

        // else{
        let res = false
        if (!values.name || values.name.trim().length === 0) {
            errors.name = "Name is required!";

            res = true
        }
        if (!values.email || values.email.trim().length === 0) {
            errors.email = "Email is required!";
            res = true


        } else if (!String(values.email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
            errors.email = 'this is not email address'
            res = true
        }
        if (!values.subject || values.subject.trim().length === 0) {
            errors.subject = "Subject is required!";
            res = true

        }

        if (!values.message || values.message.trim().length === 0) {
            errors.message = "Message is required!";
            res = true

        }
        setFormErrors(errors);
        setTimeout(() => {
            setFormErrors({
                name: "",
                email: "",
                subject: "",
                upload: "",
                message: ""
            });
        }, 5000);
        return res
        // }

        // setFormErrors(errors);
        // setTimeout(() => {
        //     setFormErrors(old=>({...old,[field]:''}));
        // }, 5000);
    }

    const [loading, setLoading] = useState(false);
    useEffect(() => emailjs.init("tFKbFKFwvWad0D0pR"), []);
    return (
        <StyleRoot>
            <div className='d-flex justify-content-center  ' >

                <div className=' shadow shadow-3  bg-white  position-absolute   ' style={style} >
                    <div className=' ' action='' method='' onSubmit={(event) => handleSubmit(event)} style={style2}>
                        <div className='row '>
                            <div className='col-12 col-md-6 row d-block'>
                                <div className='ps-0 d-flex '>
                                    <label style={style3} className='ps-0' htmlFor='text'>Full name *</label><p className='ms-auto ' style={{ color: 'red' }}>{formErrors.name}</p>

                                </div>
                                <input className=' input1' style={style4} type='text' name='name' id='text'
                                    placeholder='john David' onChange={handleChange} value={formData.name}

                                />
                            </div>
                            <div className='col-12 col-md-6 row d-block mt-4 mt-md-0'>
                                <div className='ps-0 d-flex '>
                                    <label style={style3} className='ps-0' htmlFor='email'>Your email *</label><p className='ms-auto ' style={{ color: 'red' }}>{formErrors.email}</p>
                                </div>
                                <input className='input1' style={style4} type='email' id='email' name='email'
                                    placeholder='example@yourmail.com' onChange={handleChange} value={formData.email} />
                            </div>
                        </div>
                        <div className='row '>

                            <div className='col-12 col-md-6 row d-block mt-4'>
                                <div className='ps-0 d-flex '>
                                    <label style={style3} className='ps-0' htmlFor='subject'>Subject *</label><p className='ms-auto ' style={{ color: 'red' }}>{formErrors.subject}</p>
                                </div>
                                <input className='input1' style={style4} type='text' id='subject' name='subject'
                                    placeholder='Subject here' onChange={handleChange} value={formData.subject} />
                            </div>
                            <div className='col-12 col-md-6  d-block mt-4'>
                                <label style={style3} className='ps-0' htmlFor='upload'>Upload a file *</label>

                                <div className='position-relative  row'>
                                    <input disabled className='bg-white input1' style={style4} type='text' id='upload' name='upload'
                                        placeholder='(PDF/JPG/WORD-MAX: 8MB)' value={formData.upload} />

                                    <button className="position-absolute text-primary border-0 d-flex justify-content-center align-items-center bg-secondary p-2"
                                        style={{ height: '60%', top: '20%', right: 20, borderRadius: 10, ...style11 }}
                                        onClick={importData}>
                                        <img src={folderIcon} style={style5} alt='' />
                                        <p style={style1} className=' text-primary mt-3 align-items-center'>Choose a file </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row ps-2 pe-2 mt-4'>
                            <div className='d-flex ps-0'>
                                <label style={style3} className='ps-0   ' htmlFor='msg'>Message *</label><p className='ms-auto ' style={{ color: 'red' }}>{formErrors.message}</p>
                            </div>
                            <textarea className='input1 overflow-auto' style={style6} name='message' id='msg'
                                placeholder='Hello there,I would like to talk about how to...'
                                onChange={handleChange} value={formData.message}></textarea>

                        </div>
                        <Button Loading={loading} OnClick={() => handleSubmit()} />
                    </div>
                </div>
            </div>
            <Dialog Show={showDialog} Close={() => setDialog(false)} Title='Message Successfully Sent' Body='Thank You For Your Contact' />
        </StyleRoot>
    )
}

export default FormUi
