import React from "react";
import Radium, { StyleRoot } from 'radium';

export default function MyContainer(props){
    const style = {
        marginLeft:'9.722vw',marginRight:'9.722vw',

            // '@media (max-width: 1726px)': {
            //     marginLeft:113,marginRight:116,
            // },
        '@media (max-width: 1728px)': {
            marginLeft:'9.722vw',marginRight:'9.722vw',
        },
        '@media (max-width: 834px)': {
            marginLeft:'3.597vw',marginRight:'3.597vw',
        },
        '@media (max-width: 428px)': {
            marginLeft:'4.673vw',marginRight:'4.673vw',
        },
        
  };
    return(
        <StyleRoot>
        <div className={`${props.Class}`} style={style}>
        {props.children}
    </div>
    </StyleRoot>
    )

}