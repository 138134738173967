import React from 'react'
import Radium, { StyleRoot } from 'radium'; 

const Button = (props) => {

    const style3= {
        borderRadius:50,
        paddingLeft:51.5,
        paddingRight:51.5,
        paddingTop:25,
        paddingBottom:26,
        fontSize:20,
        fontWeight:400,
        font:'DIN Next LT Arabic',

        '@media (max-width: 428px)': {
            width:'100%',
            borderRadius:10,
            paddingLeft:50,
            paddingRight:50,
            paddingTop:15,
            paddingBottom:15,
        fontSize:'3.738vw',

        },


    }
    return (
<StyleRoot>
    <div  className='d-flex justify-content-center'>
            <button onClick={()=>props.OnClick()} className='d-flex mt-5 d-flex justify-content-center border-0 btn3  text-center' style={style3} >
            <span className={`${props.Loading?'d-flex':'d-none'} mt-2 me-2 spinner-border spinner-border-sm  `} role="status" aria-hidden="true"></span>
            Send Message
            </button>
            </div>
            </StyleRoot>

    )
}

export default Button