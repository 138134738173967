import React from 'react'
import MaskIMG from '../../../../assets/images/Mask group.png'
import Radium, { StyleRoot } from 'radium';

const OurTeamRight = () => {
    const style={
        top:15,
        width:' 33.17892298784vw', height:'38.7vw',
        // '@media (max-width: 1890px)': {
        //     width: 450,
        //     height:480,
            
        //   },
    }

    const style1={
        width:'28.199189345686vw',rotate:'5deg',borderRadius:15,height:'40.5vw',
        // '@media (max-width: 1890px)': {
        //     height:500,
        //     width:400,
        //   },
    }

    const style2={
        '@media (max-width: 1250px)': {
            marginTop:0,
          },
    }
    return (
        <StyleRoot>

        <div className='position-relative d-flex ' style={style2}>
            <div className='' style={{paddingLeft:35}}>
            <div className='bg-primary  ' style={style1}/>
            </div>

            <img className='position-absolute' style={style} src={MaskIMG}  alt='' />
        </div>
        </StyleRoot>

    )
}

export default OurTeamRight