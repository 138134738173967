import React from "react";
import Radium, { StyleRoot } from 'radium';

export default function FirstTilte(props){
    const style = {
        fontSize:20,
        fontWeight:400,
        lineHeight:1.5,
        paddingBottom:10,
        font:'DIN Next LT Arabic',

        '@media (max-width: 834px)': {
            fontSize:'2.5vw',
            lineHeight:'3.525vw',        },
        '@media (max-width: 428px)': {
            fontSize:'4.673vw',
            lineHeight:'6.869vw',        },
        
  };
    return(
        <StyleRoot> 
        <h3 className='text-success' style={style} >{props.Title}</h3>
        </StyleRoot> 
    )
}